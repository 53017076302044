<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="Friability Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Friability Test Description"
              size="medium"
            />
            <p class="p-light">
              An impingement gun is used to fire a 9 gram piece of consolidated
              substance traveling 150 m/s into a manometric bomb. The pressure
              rate of rise is recorded.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test measures the friability or the resilience of an EIS
              pressed, cast or otherwise consolidated substance to severe impact
              or impingement. An extremely insensitive substance should not be
              friable per this standard test.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Substance configuration</td>
                    <td>Repeatably test a specific sample condition</td>
                    <td></td>
                    <td>
                      A 9±0.1 gram consolidated cylindrical sample with a
                      diameter of 18 mm.
                    </td>
                  </tr>
                  <tr>
                    <td>Weapon</td>
                    <td>
                      Provide a method to fire the consolidated sample at 150
                      m/s using a firing capsule consisting of 0.5 grams of
                      black powder.
                    </td>
                    <td></td>
                    <td>
                      18 mm Impingement gun. See
                      <a
                        class="link"
                        href="http://www.smsenergetics.com/test-equipment/impingement-guns"
                        target="_blank"
                        rel="noopener noreferrer"
                        >here</a
                      >
                      for example.
                    </td>
                  </tr>
                  <tr>
                    <td>Impact plate</td>
                    <td>Immediately stop the fired substance</td>
                    <td></td>
                    <td>
                      Z30C 13 stainless steel plate, 20 mm thick with front face
                      roughness of 3.2 microns (AFNOR NF E 05-015 and NF E
                      05-016 standards)
                    </td>
                  </tr>
                  <tr>
                    <td>Manometric bomb</td>
                    <td>
                      Provide method to measure the pressure rate-of-rise upon
                      ignition of the substance.
                    </td>
                    <td></td>
                    <td>108±0.5 mL volume</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Pressure rate-of-rise</td>
                    <td>Pressure probe</td>
                    <td>
                      If the average maximum (dp/dt)max value obtained at a
                      speed of 150 m/s greater than 15 MPa/ms, the substance is
                      not an EIS.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent class="pt-8" title="Setup Example" size="medium" />
            <p class="p-light">
              See the UN Manual of Tests and Criteria (6th ed.) or the TB 700-2
              document for further details.
            </p>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
