<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="EIS Gap Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="EIS Gap Test Description"
              size="medium"
            />
            <p class="p-light">
              A standard detonator is used to initiate a 95mm diameter x 95 mm
              long pellet of 50/50 pentolite or 95/5 rdx and wax with a density
              of 1.6±0.05 g/cc. That booster is siting on top of a PMMA spacer
              95 mm in diameter and 70 mm long. The spacer sits on a steel tube
              in which the sample is contained. The steel tube sits on a wedge
              or other support to ensure a small air gap between the pipe and
              the witness plate.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used by the UN, DOT, and DoD to determine the
              substance’s sensitivity to shock. In many cases, it has replaced
              the NOL Card Gap test. It is used to determine if the substance is
              sensitive to shock. If it is sensitive (the witness plate is
              holed), the substance is not a candidate for a 1.5 classification.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Initiating device</td>
                    <td>Repeatably initiate the booster</td>
                    <td></td>
                    <td>UN standard detonator or equivalent</td>
                  </tr>
                  <tr>
                    <td>Booster</td>
                    <td>
                      Provide a strong, repeatable, stable shock front to the
                      top of the sample
                    </td>
                    <td></td>
                    <td>
                      RDX/wax (95/5) or PETN/TNT (50/50), 95 mm diameter, ~95mm
                      length with a density of 1.6±0.05 g/cc
                    </td>
                  </tr>
                  <tr>
                    <td>Gap</td>
                    <td>Attenuate the shock pressure</td>
                    <td>
                      <small
                        >MIL-STD-1751A Method 1041: PMMA was initially chosen as
                        the gap material because</small
                      >
                      <p></p>
                      <ul>
                        <li>
                          It is stable to changes in temperature and humidity,
                        </li>
                        <li>
                          It matches the impedance of solid non-porous test
                          materials better than most other commonly used
                          attenuators, and
                        </li>
                        <li>
                          It is much more convenient to use than molded wax.
                        </li>
                      </ul>
                      <p>
                        <small
                          >Additional advantage of PMMA, particularly over metal
                          gaps, is that PMMA forms no damaging fragments. The
                          latter situation complicates the estimation of the
                          shock wave transmitted from the gap to the test
                          material which has already been pre-compressed by the
                          elastic wave. The disadvantage of PMMA is its
                          viscoelastic behavior and the resultant uncertainty of
                          its relaxation times. Hence, in the low pressure range
                          there is still some uncertainty about whether a
                          pressure lower than the equilibrium value should be
                          used.
                        </small>
                      </p>
                    </td>
                    <td>
                      PMMA thickness should be 95 mm in diameter and 70 mm long
                    </td>
                  </tr>
                  <tr>
                    <td>Confining medium</td>
                    <td>
                      Provide confinement, increasing the susceptibility of the
                      substance to detonation.
                    </td>
                    <td></td>
                    <td>
                      Tubing, steel, seamless: 95±7 mm OD, 9.75±2.75 mm wall
                      with an ID of 73±7 mm, and length of 280mm.
                    </td>
                  </tr>
                  <tr>
                    <td>Substance density</td>
                    <td>
                      Determine whether the substance in its in-process form is
                      sensitive to the test stimulus
                    </td>
                    <td></td>
                    <td>
                      Substance to be tested as near as possible to the shipping
                      density
                    </td>
                  </tr>
                  <tr>
                    <td>Spacer</td>
                    <td>Separate the steel tube from the witness plate</td>
                    <td>
                      <small
                        >MIL-STD-1751A Method 1041: The small stand-off
                        (0.063-inch gap) between the acceptor and the witness
                        plate was introduced to prevent the witness plate from
                        shattering and thereby facilitate interpretation of test
                        results.</small
                      >
                    </td>
                    <td>1.6 ± 0.2mm (1/16-in) thick</td>
                  </tr>
                  <tr>
                    <td>Witness plate specifications</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>
                      Mild steel (e.g., SAE 1015 – 1026) witness plate 200mm x
                      200mm x 20mm.
                    </td>
                  </tr>
                  <tr>
                    <td>Offset</td>
                    <td>Offset the witness plate from the ground</td>
                    <td></td>
                    <td>
                      2 x 4 in wood blocks at the edge of the witness plate
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Damage to the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      “Neat” hole punched through the witness plate: [Not
                      Division 1.5 if sensitive to detonation from attenuated
                      shock (~70 kbar)]
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent class="pt-8" title="Setup Example" size="medium" />
            <p class="p-light">
              See the UN Manual of Tests and Criteria (6th ed.) or the TB 700-2
              document for further details.
            </p>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
